<template>
  <div class="student-admin">
    <div class="student-admin-header">
      <div class="select-left">
        选择班级
        <el-select style="width: 200px;margin-left: 12px" @change="selectClassChange" v-model="selectClassValue" placeholder="请选择">
          <el-option
              v-for="item in classList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="select-right">
        <el-button type="primary" @click="downloadTemplate" plain>下载模板</el-button>
        <el-button type="primary" @click="importStudent" plain>导入学生</el-button>
        <el-button type="primary" @click="addStudentBtn">创建学生</el-button>
      </div>
    </div>
    <el-table class="customTable" :data="studentList" style="width: 100%; margin-top: 20px; flex: 1;border: 1px solid #EEEEEE;" height="1%" size="medium"
              :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
              :cell-style="{fontSize: '16px',color: '#333333',height: '100px'}">
      <el-table-column prop="nickname" align="center" label="学生名称"></el-table-column>
      <el-table-column prop="username" align="center" label="账号"></el-table-column>
      <el-table-column prop="id_number" align="center" label="身份证号"></el-table-column>
      <el-table-column align="center" label="证件照">
        <template slot-scope="scope">
          <div class="column-logo-box">
            <div class="column-logo">
              <img v-if="scope.row.avatar" :src="scope.row.avatar" alt="">
              <img v-else src="./../../../assets/images/teacher/default-header.png" alt="">
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" align="center" label="班级"></el-table-column>
      <el-table-column align="center" label="操作">
        <template slot-scope="scope">
          <el-link type="success" :underline="false" @click="editStudent(scope.row.id)">编辑</el-link>
          <el-link type="danger" :underline="false" @click="deleteStudent(scope.row.id)">删除</el-link>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="dialogTitle"
        custom-class="student-dialog"
        :visible.sync="addDialogVisible"
        @close="resetForm"
        @opened="openAddStudent"
        width="500px">
      <el-form ref="addForm" :model="addForm" :rules="addFormRules" label-width="90px">
        <el-form-item label="学生名称" prop="nickname">
          <el-input v-model="addForm.nickname" ref="cursorInput" placeholder="2-20字"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="id_number" >
          <el-input v-model="addForm.id_number" placeholder="18位"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-radio-group v-model="addForm.gender">
            <el-radio :label="0">女</el-radio>
            <el-radio :label="1">男</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="账号" prop="username">
          <el-input v-model="addForm.username" :disabled="addForm.id ? true : false" placeholder="4-20"></el-input>
        </el-form-item>
        <el-form-item label="重置密码" prop="setPassword" v-if="addForm.id">
          <el-input v-model="addForm.setPassword" auto-complete="new-password" minlength="6" maxlength="20" show-password placeholder="6-20"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-else>
          <el-input v-model="addForm.password" auto-complete="new-password" minlength="6" maxlength="20" show-password placeholder="6-20"></el-input>
        </el-form-item>
        <el-form-item label="选择班级" prop="class_id">
          <el-select v-model="addForm.class_id" placeholder="请选择">
            <el-option
                v-for="item in classList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="证件照">
          <div class="logo-src-box" v-if="addForm.avatar">
            <div class="src-box">
              <img :src="headerSrc" alt="">
            </div>
            <i @click="clearLogo" class="iconfont">&#xe651;</i>
          </div>
          <el-upload class="logo-uploader"
                     v-else
                     action="student/upload"
                     :headers="headersParams"
                     name="logo"
                     :show-file-list="false"
                     :on-success="handleCoverSuccess"
                     :before-upload="beforeCoverUpload">
            <i class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
          <div class="school-prompt">
            <span>尺寸295*413px</span>
            <span>格式为.jpg或.jpeg或.png；大小不超过2M的图片</span>
          </div>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button  @click="addDialogVisible = false">取 消</el-button>
        <el-button style="margin-left: 30px" type="primary" @click="confirmForm('addForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!--  导入学生弹框  -->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="导入学生"
        custom-class="student-dialog"
        :visible.sync="importDialogVisible"
        @close="resetImport"
        width="500px">
      <el-form ref="importForm" :model="importForm" :rules="importFormRules" label-width="90px">
        <el-form-item label="选择班级" prop="class_id">
          <el-select v-model="importForm.class_id" placeholder="请选择班级">
            <el-option
                v-for="item in classList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button  @click="importDialogVisible = false">取 消</el-button>
        <el-button style="margin-left: 30px" type="primary" @click="confirmImport('importForm')">导入文件</el-button>
        <input type="file" ref="excelInput" accept=".xlsx" title="" @change="changeExcel($event)" style="display: none;">
      </div>
    </el-dialog>
    <el-pagination class="pager-center" style="margin: 20px;text-align: center"
                   :current-page="listPages.currentPageNum"
                   :page-size="listPages.eachPageNum"
                   :total="listPages.total"
                   layout="prev, pager, next, jumper"
                   @current-change="listCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
import {getClassesList, studentAdd, getStudentList, studentInfo, studentDel, studentImport} from "@/utils/apis";
import axios from "axios";

export default {
  name: "StudentManagement",
  data(){
    var validateSetPass = (rule, value, callback) => {
      if(value === ''){
        callback();
      } else {
        if(value.length < 6 || value.length > 20){
          callback(new Error('长度在 6 到 20 个字'))
        } else {
          callback();
        }
      }
    };
    var validateIdNo = (rule, value, callback) => {
      const reg = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
      if (value == ''){
        callback(new Error('请输入身份证号码'));
      } else {
        if ((!reg.test(value)) && value != '') {
          callback(new Error('请输入正确的身份证号码'));
        } else {
          callback();
        }
      }
    }
    return {
      studentList:[],
      classList:[],
      selectClassValue:'',
      listPages:{
        currentPageNum: 1,
        eachPageNum: 10,
        total: 0,
      },
      addForm:{
        id:'',
        nickname:'',
        id_number:'',
        gender:'',
        username:'',
        setPassword:'',
        password:'',
        class_id:'',
        avatar:''
      },
      headerSrc:null,
      addFormRules:{
        nickname:[
          { required: true, message: '请输入学生名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        id_number:[
          { validator: validateIdNo, trigger: 'blur' }
        ],
        gender:[
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        username:[
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 4, max: 20, message: '长度在 4 到 20 个字符', trigger: 'blur' }
        ],
        password:[
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 20, message: '长度在 6 到 20 个字', trigger: 'blur' }
        ],
        setPassword:[
          { validator: validateSetPass, trigger: 'blur' }
        ],
        class_id:[
          { required: true, message: '请选择班级', trigger: 'change' }
        ]

      },
      dialogTitle:'',
      addDialogVisible:false,
      headersParams:{
        Authorization: localStorage.getItem('teacherToken') || ''
      },
      hostUrl:localStorage.getItem('hosturl') || '',
      importDialogVisible:false,
      importForm:{
        class_id:'',
      },
      importFormRules:{
        class_id:[
          { required: true, message: '请选择班级', trigger: 'change' }
        ],
      }
    }
  },
  mounted() {
    this.getClassList();
    this.getStudentListData();
  },
  methods:{
    // 下载模板
    downloadTemplate(){
      window.open(this.hostUrl + 'student/export')
    },
    // 导入学生
    importStudent(){
      this.importDialogVisible = true;
    },
    // 关闭导入学生
    resetImport(){
      this.$refs.importForm.resetFields();
    },
    // 选择班级后点击导入
    confirmImport(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          this.$refs.excelInput.click();
        } else {
          return false
        }
      })
    },
    // 上传文件
    changeExcel(event){
      if (event.target.files.length === 0) return;
      let fileList = event.target.files[0];
      let formData = new FormData();
      formData.append('class_id', this.importForm.class_id);
      formData.append('file', fileList);
      studentImport(formData).then((res)=>{
        this.$message({
          message:res.msg,
          type:'success',
          duration:1000,
          onClose:()=>{
            this.$refs.excelInput.value = '';
            this.importDialogVisible = false;
            this.getStudentListData();
          }
        })
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    getClassList(){
      getClassesList({}).then((res)=>{
        this.classList = res.data;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    getStudentListData(){
      let params = {
        page:this.listPages.currentPageNum,
        limit:this.listPages.eachPageNum
      }
      if(this.selectClassValue){
        params.class_id = this.selectClassValue
      }
      getStudentList(params).then((res)=>{
        this.studentList = res.data.list;
        this.listPages.total = res.data.total;
      }).catch((err)=>{
        console.log('err', err)
      })
    },
    resetForm(){
      this.$refs.addForm.resetFields();
      this.addForm.id = '';
      this.headerSrc = null;
      this.addForm.avatar = '';
    },
    openAddStudent(){
      if(this.addForm.id){
        studentInfo(this.addForm.id).then((res)=>{
          this.addForm.username = res.data.username;
          this.addForm.nickname = res.data.nickname;
          this.addForm.id_number = res.data.id_number;
          this.addForm.gender = res.data.gender;
          this.addForm.class_id = res.data.class_id;
          this.addForm.avatar = res.data.avatar;
          this.headerSrc = this.hostUrl + res.data.avatar;
        }).catch((err)=>{
          console.log('err', err)
        })
      }
      this.$refs.cursorInput.focus();
    },
    addStudentBtn(){
      this.dialogTitle = '创建学生';
      this.addDialogVisible = true;
    },
    // 删除头像
    clearLogo(){
      this.headerSrc = null;
      this.addForm.avatar = '';
    },
    isWidth(file){
      let _this = this;
      return new Promise((resolve, reject)=>{
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function(e){
          let image = new Image();
          image.src = e.target.result;
          image.onload = function() {
            let w = this.width;
            let h = this.height;
            if (w !== 295 || h !== 413) {
              _this.$message.info('请上传尺寸为295*413px的图片');
              reject(false)
            } else {
              resolve(true)
            }
          }
        }
      })
    },
    // start 上传图片
    async beforeCoverUpload(file) {
      const isImg = file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg') || file.name.toLowerCase().endsWith('.png');
      if (!isImg) {
        this.$message.error('上传图片只能是 jpg 或 jpeg 或 png 格式!');
        return false;
      }
      let isWidth = await this.isWidth(file)
      return isImg && isWidth;
    },
    // 上传图片
    handleCoverSuccess(res, file){
      if (res.code === 200) {
        this.headerSrc = this.hostUrl + res.data.src; // 有域名
        this.addForm.avatar = res.data.src; //无域名
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
    },
    confirmForm(formName){
      this.$refs[formName].validate((valid)=>{
        if(valid){
          let formData = new FormData();
          formData.append('nickname',this.addForm.nickname);
          formData.append('gender', this.addForm.gender);
          formData.append('id_number', this.addForm.id_number);
          formData.append('class_id', this.addForm.class_id);
          formData.append('avatar', this.addForm.avatar);
          if(this.addForm.id){
            formData.append('id', this.addForm.id)
            if(this.addForm.setPassword){
              formData.append('password', this.addForm.setPassword)
            }
          } else {
            formData.append('password', this.addForm.password);
            formData.append('username', this.addForm.username);
          }
          studentAdd(formData).then((res)=>{
            this.$message({
              message:res.msg,
              type:'success',
              duration:1000,
              onClose:()=>{
                this.addDialogVisible = false;
                this.getStudentListData()
              }
            })
          }).catch((err)=>{
            console.log('err', err)
          })
        } else {
          return false;
        }
      })
    },
    editStudent(id){
      this.addForm.id = id;
      this.dialogTitle = '编辑学生';
      this.addDialogVisible = true;
    },
    deleteStudent(id){
      this.$confirm(`是否删除该学生，删除将无法恢复`, '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass:'green-message-box',
        type:'warning'
      }).then(()=>{
        studentDel(id).then((res)=>{
          this.$message({
            message:res.msg,
            type:'success',
            duration:1000,
            onClose:()=>{
              this.getStudentListData()
            }
          })
        }).catch((err)=>{
          console.log('err', err)
        })
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
    selectClassChange(){
      this.getStudentListData()
    },
    listCurrentChange(val){
      this.listPages.currentPageNum = val;
      this.getStudentListData();
    }
  }
}
</script>

<style scoped lang="scss">
.student-admin{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .student-admin-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
::v-deep .student-dialog{
  .el-dialog__header{
    padding: 16px 24px;
    background: #2DC079;
    span,i{
      font-size: 16px;
      line-height: 1;
      color: #fff;
    }
  }
  .el-dialog__body{
    padding:22px 24px;
    // 带表格的
    .el-form-item{
      margin-bottom: 20px;
    }
    .el-form-item__label{
      font-size: 16px;
      color: #666;
    }
    .dialog-footer{
      text-align: center;
      margin: 24px 0 18px;
    }
  }
}
.school-prompt{
  color: #999;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  span{
    line-height: 1;
    margin-top: 10px;
  }
}
.logo-src-box{
  width: 57px;
  height: 80px;
  box-sizing: border-box;
  position: relative;
  .src-box{
    width: 100%;
    height: 100%;
    overflow: hidden;
    border: 1px solid #EEEEEE;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  i{
    line-height: 1;
    font-size: 14px;
    color: #DCDCDC;
    position: absolute;
    right: -20px;
    top: -10px;
    cursor: pointer;
  }
}
.column-logo-box{
  display: flex;
  align-items: center;
  justify-content: center;
  .column-logo{
    width: 57px;
    height: 80px;
    overflow: hidden;
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
}
.logo-uploader{
  ::v-deep .el-upload{
    width: 57px;
    height: 80px;
    background: #EEEEEE;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo-uploader-icon{
      font-size: 32px;
      color: #A0A0A0;
    }
  }
}
</style>